import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MapUsers from './MapUsers'
import SearchContainer from './SearchContainer'
import Spinner from './Spinner'
import OtherLinks from './OtherLinks'


const TotalGold = ({ userBanks }) => {
  const usersTotalGold = userBanks
    .map((user) => user.bankedGold + user.currentGold)
    .reduce((acc, curr) => acc + curr)

  return (
    <div className="total-gold">
      <img alt="" src={`${process.env.REACT_APP_DOMAIN}/items/00071_00.gif`} />
      <span>{`Total Gold: ${usersTotalGold.toLocaleString()}`}</span>
    </div>
  )
}

function App() {
  const [error, setError] = useState(false),
    [message, setMessage] = useState(false),
    [filterInput, setFilterInput] = useState(''),
    [loaded, setLoaded] = useState(false),
    [loading, setLoading] = useState(false),
    [showBank, setShowBank] = useState(true),
    [showDuplicates, setShowDuplicates] = useState(true),
    [showEquipment, setShowEquipment] = useState(true),
    [showGold, setShowGold] = useState(true),
    [showInventory, setShowInventory] = useState(true),
    [sorted, setSorted] = useState(false),
    [loadDisabled, setLoadDisabled] = useState(false),
    [filterNonDuplicates, setFilterNonDuplicates] = useState(false),
    [userBanks, setUserBanks] = useState([])

  useEffect(async () => {
    if (!window.location.search || !window.location.search.includes('=')) return

    const searchString = [...new Set(window.location.search.split('=')[1].split(','))]

    setLoadDisabled(true)

    searchString.forEach(async (char) => {
      const userData = await fetchUserData(char)

      if (userData) {
        getBank(userData, char)
      }
    })

    window.setTimeout(function () {
      setLoadDisabled(false)

      setMessage(false)
    }, 2000)
  }, [])

  async function getAllUserData(prevChars) {
    setLoadDisabled(true)

    await prevChars.forEach(async (char) => {
      const userData = await fetchUserData(char)

      if (userData) {
        getBank(userData, char)
      }
    })

    window.setTimeout(function () {
      setLoadDisabled(false)

      setMessage(false)
    }, 2000)
  }

  async function loadCharactersFromLocalStorage() {
    if (window && window.localStorage && window.localStorage.getItem('characters')) {
      try {
        const prevChars = JSON.parse(window.localStorage.getItem('characters'))

        if (!loadDisabled) {
          await getAllUserData(prevChars)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      } finally {
        setMessage('Characters loaded from local storage!')
      }
    }
  }

  function saveCharactersToLocalStorage() {
    if (window && window.localStorage) {
      const userNames = userBanks.map((user) => user.name)

      window.localStorage.setItem('characters', JSON.stringify(userNames))

      setMessage('Characters saved to local storage!')

      window.setTimeout(function () {
        setMessage(false)
      }, 2000)
    }
  }

  const fetchUserData = async (name) => {
    try {
      const duplicateFound = userBanks.find((user) => {
        return user.name.toLowerCase() === name.toLowerCase()
      })

      if (!name || name.trim() === '') {
        setError('Name cannot be blank!')

        setLoading(false)

        return
      }

      if (duplicateFound) {
        setError(`${name} was already added!`)

        setLoading(false)

        return
      }

      setLoading(true)

      const lowerCaseName = name.toLowerCase()
      const { data } = await axios.get(process.env.REACT_APP_PROFILE + lowerCaseName)

      return data
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)

      setError(`Could not retrieve ${name}`)

      setLoading(false)

      return
    }
  }

  const getBank = async (data, name) => {
    const { charName, bankedGold = 0, equipment, currentGold = 0, gold = 0, inventory = [], bank = [], itemsUpdated } = data

    let fixedEquipment = []

    if (equipment && Array.isArray(equipment)) {
      fixedEquipment = equipment
    }


    try {
      if (data === 'error') {
        setError('No userpage found.')

        setLoading(false)

        return
      }


      setUserBanks((prev) => {
        const allAddedCharNames = prev.map((p) => p.name.toLowerCase()).concat(name.toLowerCase())

        let queryString = '?chars=' + allAddedCharNames.join(',')

        window.history.replaceState(null, '', queryString)

        return prev.concat({
          name,
          charName,
          bankedGold: bankedGold === 0 && currentGold === 0 && gold > 0 ? gold : bankedGold, // TODO: remove this when all users have bankedGold
          currentGold,
          equipment: fixedEquipment,
          inventory, // TODO: remove this when all users have inventory
          bank,
          itemsUpdated,
        })
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)

      setError('An error occurred...')

      setLoading(false)

      return
    } finally {
      // setGreeting(false);
      setLoading(false)

      setLoaded(true)

      setError(false)
    }
  }

  const handleRemove = (user) => {
    const removedUser = userBanks.filter((char) => char.charName !== user)

    setUserBanks(removedUser)

    const queryString = '?chars=' + removedUser.map((u) => u.name.toLowerCase()).join(',')

    if (removedUser.length) {
      window.history.replaceState(null, '', queryString)
    } else {
      window.history.replaceState(null, '', '/')
    }
  }

  return (
    <div className="App">
      <OtherLinks />
      <div className="message">{message && message}</div>
      <div className="error">{error && error}</div>
      <SearchContainer
        fetchUserData={fetchUserData}
        getBank={getBank}
        handleFilter={(e) => {
          return setFilterInput(e.target.value)
        }}
        handleSort={() => setSorted((prev) => !prev)}
        handleShowBank={() => setShowBank((prev) => !prev)}
        handleShowDuplicates={() => setShowDuplicates((prev) => !prev)}
        handleShowEquipment={() => setShowEquipment((prev) => !prev)}
        handleShowGold={() => setShowGold((prev) => !prev)}
        handleShowInventory={() => setShowInventory((prev) => !prev)}
        filterNonDuplicates={filterNonDuplicates}
        handleFilterNonDuplicates={() => setFilterNonDuplicates((prev) => !prev)}
        showBank={showBank}
        showEquipment={showEquipment}
        showDuplicates={showDuplicates}
        showGold={showGold}
        showInventory={showInventory}
        loadCharactersFromLocalStorage={loadCharactersFromLocalStorage}
        saveCharactersToLocalStorage={saveCharactersToLocalStorage}
        loadDisabled={loadDisabled}
        userBanks={userBanks}
      />
      {loading && <Spinner />}
      {showGold && loaded && userBanks.length > 1 ? (
        <TotalGold userBanks={userBanks} />
      ) : (
        <div className="total-gold-placeholder" />
      )}
      <div className="container">
        {loaded && (
          <MapUsers
            filterNonDuplicates={filterNonDuplicates}
            handleRemove={handleRemove}
            filterInput={filterInput}
            showBank={showBank}
            showEquipment={showEquipment}
            showGold={showGold}
            showInventory={showInventory}
            showDuplicates={showDuplicates}
            sorted={sorted}
            users={userBanks}
          />
        )}
      </div>
    </div>
  )
}

export default App
