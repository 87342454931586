/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'

const fixImg = (src) =>
  src ? src.replace(/gif.*/, 'gif').replace(/^[^_]*..\//, `${process.env.REACT_APP_DOMAIN}/items/`) : ''

const isMoreThan3DaysAfter = (isoDate) => {
  const date = new Date(isoDate)
  const currentDate = new Date()
  const diffInMs = currentDate.getTime() - date.getTime()
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24)

  return diffInDays > 3
}

// memo to cut unnecessary rerenders when highlighting duplicates
const Item = React.memo(({ item, src, count, duplicate }) => {
  const [visible, setVisible] = useState(true)

  return (
    <tr>
      <td style={{ textAlign: 'center' }}>
        {visible ? <img style={{ maxHeight: 30, maxWidth: 30 }} src={fixImg(src)} onError={() => setVisible(false)} /> : <div style={{ height: 30, width: 30 }} />}
      </td>
      <td style={{ width: 5 }} />
      <td className={duplicate ? 'highlighted' : 'normal-text'}>{item}</td>
      <td style={{ width: 10 }} />
      <td>{count}</td>
    </tr>
  )
})

const Character = ({ handleRemove, charName, shortName, itemsUpdated }) => {
  if (!charName) return null

  return (
    <div className="centered">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="char" style={{ opacity: isMoreThan3DaysAfter(itemsUpdated) ? 0.4 : 1 }}>{shortName}</div>
      </div>
      <div>
        <img src={`${process.env.REACT_APP_GOOGLE_CLOUD_STORAGE}characters/${charName.toLowerCase()}.png`} alt={charName} />
      </div>
      <div>
        <button type="button" onClick={() => handleRemove(charName)}>
          {'Remove'}
        </button>
      </div>
    </div>
  )
}

const BankSlots = ({ totalBanked }) => (
  <div className="bank-slots">{totalBanked > 0 && `Bank slots used: ${totalBanked}`}</div>
)

const GoldTable = React.memo(({ showGold, currentGold, bankedGold }) => {
  return (
    showGold && (
      <table className="gold-table">
        <tbody>
          <tr>
            <td />
            <td>{'Current:'}</td>
            <td className="text-right gold">{currentGold.toLocaleString()}</td>
          </tr>
          <tr>
            <td />
            <td>{'Banked:'}</td>
            <td className="text-right gold">{bankedGold.toLocaleString()}</td>
          </tr>
          <tr>
            <td>
              <img alt="" src={`${process.env.REACT_APP_DOMAIN}/items/00071_00.gif`} />
            </td>
            <td>{'Total:'}</td>
            <td className="text-right border-top gold bold">{(currentGold + bankedGold).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    )
  )
})

const ItemsContainer = React.memo(
  ({ unsortedItems, sorted, showDuplicates, duplicatesArray, filterNonDuplicates }) => {
    const sortedItems = unsortedItems.slice().sort((a, b) => {
      if (!a.item || !b.item) return 0

      const nameA = a.item.toUpperCase()
      const nameB = b.item.toUpperCase()

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    })

    const items = sorted ? sortedItems : unsortedItems

    return (
      <div>
        <table id="item-table" style={{ width: '100%' }}>
          <tbody>
            {items.length < 1 ? (
              <tr>
                <td>{'No items shown.'}</td>
              </tr>
            ) : filterNonDuplicates ? (
              items
                .filter((item) => duplicatesArray.includes(item.item))
                .map((itemObj, index) => {
                  const { item, src, count } = itemObj

                  if (!item || isNaN(count)) return null

                  return (
                    <Item key={index + item + count} item={item} src={src} count={count} duplicate={true} />
                  )
                }).filter(Boolean)
            ) : (
              items.map((itemObj, index) => {
                const { item, src, count } = itemObj

                if (!item || isNaN(count)) return null

                return (
                  <Item
                    key={index + item + count}
                    item={item}
                    src={src}
                    count={count}
                    duplicate={showDuplicates && duplicatesArray.length > 0 && duplicatesArray.includes(item)}
                  />
                )
              }).filter(Boolean)
            )}
          </tbody>
        </table>
      </div>
    )
  },
)

export const findDuplicatesAndSum = (inputArray) => {
  const duplicateIndex = {}
  const outputArr = []

  for (let i = 0; i < inputArray.length; i++) {
    const item = inputArray[i].item
    const count = inputArray[i].count
    const collisionIndex = duplicateIndex[item]

    if (!item || isNaN(count)) continue

    if (collisionIndex > -1) {
      outputArr[collisionIndex].count += count
    } else {
      outputArr.push({ ...inputArray[i], item, count })

      duplicateIndex[item] = outputArr.length - 1
    }
  }

  return outputArr
}

const countItems = (items) =>
  items.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.item]: (acc[curr.item] || 0) + 1,
    }),
    {},
  )

const findDuplicates = (dict) => Object.keys(dict).filter((a) => dict[a] > 1)

const MapUsers = React.memo(
  ({
    filterInput,
    handleRemove,
    sorted,
    users,
    showDuplicates,
    showEquipment,
    showGold,
    showInventory,
    showBank,
    filterNonDuplicates,
  }) => {
    const mergeItems = (user) => {
      let output = []

      if (showEquipment && user.equipment) {
        const fixedEquipment = user.equipment.map((item) => ({ ...item, count: 1 }))

        output = [...output, ...fixedEquipment]
      }

      if (showInventory && user.inventory) {
        output = [...output, ...user.inventory]
      }

      if (showBank && user.bank) {
        output = [...output, ...user.bank]
      }

      return findDuplicatesAndSum(output)
    }

    const mergedUserItems = users.reduce((acc, curr) => {
      return [...acc, ...mergeItems(curr)]
    }, [])

    const countedMergedItems = countItems(mergedUserItems)
    const duplicatesArray = findDuplicates(countedMergedItems)

    const usersWithMergedFilteredItems = users.map((user) => {
      const splitName = user.charName ? user.charName.split(' ') : user.name
      const shortName = splitName[splitName.length - 1]
      const mergedItems = mergeItems(user)

      const filteredItems = !!filterInput
        ? mergedItems.filter((i) => i.item.toLowerCase().includes(filterInput.toLowerCase()))
        : mergedItems

      if (!!filterInput && !filteredItems.length) return null

      return { ...user, filteredItems, mergedItems, shortName }
    }).filter(Boolean)

    if (!!users.length && !usersWithMergedFilteredItems.length) return <h1 style={{ color: 'aliceblue', textAlign: 'center', width: '100%' }}>{'Item not found. Refine your search.'}</h1>

    return usersWithMergedFilteredItems.map((user) => {
      const { charName, bank, currentGold, bankedGold, filteredItems, mergedItems, shortName, itemsUpdated } = user

      return (
        <div style={{ minWidth: 220 }} className="spacer" key={charName}>
          <Character handleRemove={handleRemove} charName={charName} shortName={shortName} itemsUpdated={itemsUpdated} />
          <BankSlots totalBanked={bank.length} items={mergedItems} />
          <GoldTable showGold={showGold} currentGold={currentGold} bankedGold={bankedGold} />
          <ItemsContainer
            unsortedItems={filteredItems}
            sorted={sorted}
            showDuplicates={showDuplicates}
            duplicatesArray={duplicatesArray}
            filterNonDuplicates={filterNonDuplicates}
          />
        </div>
      )
    })
  },
)

export default MapUsers
