import React from 'react'

const Checkbox = ({ onClickHandler, checked, label }) => {
    return (
      <span>
        <input
          style={{ marginRight: 10 }}
          onChange={onClickHandler}
          checked={checked}
          type="checkbox"
        />
        {label}
      </span>
    )
  }

function CheckboxList({
    handleShowBank,
    handleShowDuplicates,
    handleShowEquipment,
    handleShowGold,
    handleShowInventory,
    showBank,
    showEquipment,
    showDuplicates,
    showGold,
    showInventory,
    handleFilterNonDuplicates,
    filterNonDuplicates,
  }) {
    return (
      <div className="checkbox-container-outer">
        <div className="checkbox-container-inner">
          <Checkbox
            onClickHandler={handleShowEquipment}
            checked={showEquipment}
            label={'Include Equipment'}
          />
          <Checkbox
            onClickHandler={handleShowInventory}
            checked={showInventory}
            label={'Include Inventory'}
          />
          <Checkbox
            onClickHandler={handleShowBank}
            checked={showBank}
            label={'Include Bank'}
          />
          <Checkbox
            onClickHandler={handleShowGold}
            checked={showGold}
            label={'Include Gold'}
          />
          <Checkbox
            onClickHandler={handleShowDuplicates}
            checked={showDuplicates}
            label={'Highlight duplicates'}
          />
          <Checkbox
            onClickHandler={handleFilterNonDuplicates}
            checked={filterNonDuplicates}
            label={'Duplicates only'}
          />
        </div>
      </div>
    )
  }

  export default CheckboxList