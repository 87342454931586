import React from 'react'
import { Tooltip } from 'antd'

export default function OtherLinks() {
  return (
    <div style={{ position: 'fixed', marginLeft: '10px' }}>
      <Tooltip placement="right" title={'Stat Tracker'} mouseLeaveDelay={0.01}>
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <a href={'https://bils.ca'}>
            <svg
              viewBox="-40 0 400 150"
              fill="#79c0ff"
              style={{ height: '35px', width: '35px' }}
              xmlns="http://www.w3.org/2000/svg"
              id="XMLID_241_"
            >
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M235.443,4.316C234.064,1.675,231.331,0,228.35,0H111.391c-3.537,0-6.654,2.341-7.666,5.729l-41.78,140.009  c-0.723,2.422-0.259,5.029,1.251,7.055c1.51,2.027,3.889,3.207,6.415,3.207h65.579l-26.419,132.38  c-0.769,3.85,1.369,7.697,5.047,9.07c0.917,0.342,1.863,0.511,2.796,0.511c2.806,0,5.496-1.48,6.954-4.04l103.03-180.928  c1.41-2.477,1.396-5.533-0.036-7.997c-1.432-2.464-4.066-3.996-6.916-3.996h-46.392l61.658-88.415  C236.617,10.14,236.822,6.959,235.443,4.316z"
              />
            </svg>
            </a>
        </div>
      </Tooltip>
      <Tooltip placement="right" title={'Stat Calculator'}>
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <a href={'https://calc.bils.ca'}>
            <svg
              viewBox="-60 0 650 400"
              fill="#79c0ff"
              style={{ height: '35px', width: '35px' }}
              xmlns="http://www.w3.org/2000/svg"
              id="XMLID_241_"
            >
              <path d="M369.635,0H90.365C73.595,0,60,13.595,60,30.365v399.27C60,446.405,73.595,460,90.365,460h279.27    c16.77,0,30.365-13.595,30.365-30.365V30.365C400,13.595,386.405,0,369.635,0z M108.204,343.61v-43.196    c0-3.451,2.797-6.248,6.248-6.248h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196    C111.001,349.858,108.204,347.06,108.204,343.61z M108.204,256.61v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196    c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196C111.001,262.858,108.204,260.06,108.204,256.61    z M308.891,421H151.109c-11.046,0-20-8.954-20-20c0-11.046,8.954-20,20-20h157.782c11.046,0,20,8.954,20,20    C328.891,412.046,319.937,421,308.891,421z M208.402,294.165h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196    c0,3.451-2.797,6.248-6.248,6.248h-43.196c-3.451,0-6.248-2.797-6.248-6.248v-43.196    C202.154,296.963,204.951,294.165,208.402,294.165z M202.154,256.61v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196    c3.451,0,6.248,2.797,6.248,6.248v43.196c0,3.451-2.797,6.248-6.248,6.248h-43.196C204.951,262.858,202.154,260.06,202.154,256.61    z M345.548,349.858h-43.196c-3.451,0-6.248-2.797-6.248-6.248v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196    c3.451,0,6.248,2.797,6.248,6.248v43.196h0C351.796,347.061,348.999,349.858,345.548,349.858z M345.548,262.858h-43.196    c-3.451,0-6.248-2.797-6.248-6.248v-43.196c0-3.451,2.797-6.248,6.248-6.248h43.196c3.451,0,6.248,2.797,6.248,6.248v43.196h0    C351.796,260.061,348.999,262.858,345.548,262.858z M354,149.637c0,11.799-9.565,21.363-21.363,21.363H127.364    C115.565,171,106,161.435,106,149.637V62.363C106,50.565,115.565,41,127.364,41h205.273C344.435,41,354,50.565,354,62.363V149.637    z" />
            </svg>
          </a>
        </div>
      </Tooltip>
      <Tooltip placement="right" title={'Bank Organizer'}>
        <div style={{ textAlign: 'center', marginTop: '5px' }}>
          <a href={'/'}>
            <svg
              viewBox="-50 0 650 400"
              fill="#79c0ff"
              style={{ height: '35px', width: '35px' }}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M155.2,241.7c66.7,0,120.8-54.1,120.8-120.8S221.9,0,155.2,0S34.4,54.1,34.4,120.8S88.5,241.7,155.2,241.7z M113,168.6    c1.1-4,2.2-7.9,3.4-11.9c1.4-4.6,2.7-5.1,6.9-2.9c7.2,3.7,14.8,5.8,22.8,6.8c5.1,0.6,10.2,0.1,14.9-2c8.9-3.9,10.3-14.2,2.8-20.4    c-2.6-2.1-5.5-3.7-8.5-5c-7.8-3.4-15.9-6-23.3-10.4c-11.9-7.1-19.5-17-18.6-31.5c1-16.4,10.3-26.7,25.3-32.1    c6.2-2.3,6.2-2.2,6.3-8.7c0-2.2,0-4.4,0-6.6c0.1-4.9,1-5.7,5.8-5.9c1.5,0,3,0,4.5,0c10.4,0,10.4,0,10.4,10.4c0,7.3,0,7.4,7.3,8.5    c5.6,0.9,10.9,2.5,16.1,4.8C192,63,193,65,192.2,68c-1.3,4.5-2.5,9-4,13.4c-1.4,4.2-2.7,4.8-6.7,2.9c-8.1-3.9-16.6-5.6-25.6-5.1    c-2.4,0.1-4.6,0.4-6.8,1.4c-7.7,3.4-9,11.9-2.4,17.2c3.3,2.7,7.1,4.6,11.1,6.2c6.8,2.8,13.7,5.6,20.3,9.2    c20.7,11.5,26.3,37.5,11.7,55.2c-5.3,6.4-12.1,10.7-20.2,12.9c-3.5,1-5.1,2.8-4.9,6.4c0.2,3.6,0,7.1,0,10.7c0,3.2-1.6,4.9-4.8,5    c-3.8,0.1-7.7,0.1-11.5,0c-3.4-0.1-5-2-5-5.3c0-2.6,0-5.2-0.1-7.8c-0.1-5.7-0.2-6-5.8-6.9c-7.1-1.1-14-2.7-20.5-5.9    C112.1,175.2,111.6,173.9,113,168.6z" />
              <path d="M426.2,251.8h-14.1l0,0c-9.3-28.5-27.5-53.8-51.8-73.4c0-0.9-0.2-1.9-0.4-2.8c-5.1-17.9,1.2-34.2,8.5-45.9    c5.1-8.2-1.5-18.7-11.1-17.5c-21.5,2.7-36.3,10.6-46.5,19.5c-7,6.1-12,14.3-14.2,23.4c-15.4,63.8-72.9,111.3-141.4,111.3    c-33.3,0-63.9-11.2-88.4-30c-8.5,18.4-13.1,38.5-13.1,59.6c0,11.4,1.4,22.5,4,33.2l0,0c0,0,0,0.2,0.1,0.5    c2.7,11.1,6.8,21.8,12,31.9c6.9,14.9,16.9,32.3,30.7,46.4c19.9,20.5,23.3,45.9,23.8,56.9v4.7c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0,0.1    l0,0c0.4,5.4,4.9,9.7,10.4,9.7h63.2c5.7,0,10.4-4.6,10.4-10.4V454c9.2,1.2,18.6,1.9,28.1,1.9c7.5,0,14.8-0.4,22.1-1.2v14.9    c0,5.7,4.6,10.4,10.4,10.4h63.4c5.7,0,10.4-4.6,10.4-10.4V454c0.1-5.2,1.9-25.9,21.5-43.6c0.7-0.6,1.4-1.2,2.1-1.8    c0.2-0.2,0.4-0.3,0.6-0.5l0,0c21.8-19.4,37.9-43.6,46.2-70.6h13.3c10.6,0,19.2-8.6,19.2-19.2V271    C445.4,260.4,436.8,251.8,426.2,251.8z M347.1,255.2c-8.7,0-15.7-7-15.7-15.7s7-15.7,15.7-15.7s15.7,7,15.7,15.7    C362.8,248.2,355.8,255.2,347.1,255.2z" />
            </svg>
          </a>
        </div>
      </Tooltip>
    </div>
  )
}
