import React, { useState } from 'react'
import CheckboxList from './CheckboxList'
import DebouncedInput from 'react-debounce-input'
import { findDuplicatesAndSum } from './MapUsers'

function DownloadCSVButton({ userBanks, showEquipment, showInventory, showBank }) {
  const noData = !userBanks || !userBanks.length

  const mergeItems = (user) => {
    let output = []

    if (showEquipment) {
      output = [...output, ...user.equipment]
    }

    if (showInventory) {
      output = [...output, ...user.inventory]
    }

    if (showBank) {
      output = [...output, ...user.bank]
    }

    return findDuplicatesAndSum(output)
  }

  return (
    <button
      data-tip="Download CSV"
      type="button"
      style={{ cursor: noData ? 'not-allowed' : 'pointer', opacity: noData ? 0.2 : 1 }}
      className="button"
      onClick={() => {
        if (noData) return

        const shapedDataForCSV = userBanks.flatMap((user) => {
          const { name, currentGold, bankedGold } = user
          const mergedItems = mergeItems(user)
          const totalGold = currentGold + bankedGold

          return [['Total Gold', totalGold, name]].concat(
            mergedItems.sort((a, b) => a.item.localeCompare(b.item)).map((d) => [d.item, d.count, name]),
          )
        })

        const characterNames = userBanks
          .map(({ name }) => name.toLowerCase())
          .sort((a, b) => a.localeCompare(b))
          .join('-')

        const timestamp = new Date().toISOString().split('T')[0]
        const CSVOutput = 'data:text/csv;charset=utf-8,' + shapedDataForCSV.map((d) => d.join(',')).join('\n')
        const encodedUri = encodeURI(CSVOutput)
        const link = document.createElement('a')

        link.setAttribute('href', encodedUri)

        link.setAttribute(
          'download',
          `${characterNames}_${timestamp}_${showEquipment && 'E'}${showInventory && 'I'}${
            showBank && 'B'
          }.csv`,
        )

        document.body.appendChild(link) // Required for FF

        link.click()

        link.remove()
      }}
    >
      {'Download CSV'}
    </button>
  )
}

function SearchButton({ getBank, search, fetchUserData }) {
  return (
    <button
      data-tip="Search"
      type="button"
      className="button"
      onClick={async () => {
        const userData = await fetchUserData(search)

        if (userData) {
          getBank(userData, search)
        }
      }}
    >
      <i className="fas fa-search" />
    </button>
  )
}

function SortButton({ handleSort }) {
  return (
    <button data-tip="Sort" type="button" className="button" onClick={handleSort}>
      <i className="fas fa-sort-alpha-down" />
    </button>
  )
}

function CharacterNameInput({ setSearch, fetchUserData, getBank, search }) {
  return (
    <input
      placeholder="Character Name"
      className="input"
      onChange={(e) => setSearch(e.target.value)}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          try {
            const userData = await fetchUserData(search)

            if (userData) {
              getBank(userData, search)
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e)
          }
        }
      }}
    />
  )
}

function ItemNameInput({ handleFilter }) {
  return (
    <DebouncedInput
      placeholder="Item Name"
      className="filter"
      debounceTimeout={100}
      onChange={handleFilter}
    />
  )
}

const SearchContainer = React.memo(
  ({
    fetchUserData,
    getBank,
    handleFilter,
    handleSort,
    showDuplicates,
    handleShowBank,
    handleShowDuplicates,
    handleShowEquipment,
    handleShowGold,
    handleShowInventory,
    showBank,
    showEquipment,
    showGold,
    showInventory,
    loadCharactersFromLocalStorage,
    saveCharactersToLocalStorage,
    loadDisabled,
    handleFilterNonDuplicates,
    filterNonDuplicates,
    userBanks,
  }) => {
    const [search, setSearch] = useState('')

    return (
      <div>
        <CharacterNameInput
          fetchUserData={fetchUserData}
          getBank={getBank}
          search={search}
          setSearch={setSearch}
        />
        <SearchButton getBank={getBank} search={search} fetchUserData={fetchUserData} />
        <SortButton handleSort={handleSort} />
        <ItemNameInput handleFilter={handleFilter} />
        <SaveButton saveCharactersToLocalStorage={saveCharactersToLocalStorage} />
        <LoadButton
          loadCharactersFromLocalStorage={loadCharactersFromLocalStorage}
          loadDisabled={loadDisabled}
        />
        <DownloadCSVButton
          userBanks={userBanks}
          showBank={showBank}
          showEquipment={showEquipment}
          showInventory={showInventory}
        />
        <CheckboxList
          handleFilterNonDuplicates={handleFilterNonDuplicates}
          filterNonDuplicates={filterNonDuplicates}
          showDuplicates={showDuplicates}
          handleShowBank={handleShowBank}
          handleShowDuplicates={handleShowDuplicates}
          handleShowEquipment={handleShowEquipment}
          handleShowGold={handleShowGold}
          handleShowInventory={handleShowInventory}
          showBank={showBank}
          showEquipment={showEquipment}
          showGold={showGold}
          showInventory={showInventory}
        />
      </div>
    )
  },
)

function SaveButton({ saveCharactersToLocalStorage }) {
  return (
    <button type="button" className="button" onClick={saveCharactersToLocalStorage}>
      {'Save'}
    </button>
  )
}

const LoadButton = React.memo(({ loadCharactersFromLocalStorage, loadDisabled }) => {
  return (
    <button type="button" className="button" onClick={loadCharactersFromLocalStorage} disabled={loadDisabled}>
      {'Load'}
    </button>
  )
})

export default SearchContainer
